import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { baseURL } from 'src/constants/baseURL';
import { logout } from './authSlice';
import { useNavigate } from 'react-router-dom';
import { store } from 'src/redux/store';
import secureLocalStorage from 'react-secure-storage';

const api = axios.create({
  baseURL: baseURL // our API base URL
});

// Request interceptor for adding the bearer token
api.interceptors.request.use(
  (config) => {
    const token = secureLocalStorage.getItem('userToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      toast.error('No Session were found!');
      store.dispatch(logout());
      secureLocalStorage.clear();
      return;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Request interceptor for adding the bearer token
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data?.status === 401) {
      const token = secureLocalStorage.getItem('userToken');
      if (token) {
        toast.error('Session Expired');
      } else {
        toast.error('No Session were found!');
      }
      store.dispatch(logout());
      secureLocalStorage.clear();
    } else if (error?.response?.data?.status === 403) {
      toast.error('Restricted Route!!');
      store.dispatch(logout());
      secureLocalStorage.clear();
    }
    return Promise.reject(error);
  }
);

// Export the api instance
export default api;
