import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Link, Stack, IconButton, InputAdornment, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { toast } from 'react-toastify';
import TextInput from 'src/components/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { userSignup } from '../../../features/auth/authActions';
import { Link as RouterLink } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export default function SignupForm() {
  const navigate = useNavigate();
  const { loading, error, userInfo, success } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const token = secureLocalStorage.getItem('userToken');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (token) {
      toast.success(`Welcome ${userInfo?.firstname || ''} ${userInfo?.lastname || ''}`);
      navigate('/dashboard', { replace: true });
    }
  }, [token, error, navigate]);

  const onSubmit = (data) => {
    if (data) {
      const transformedData = {
        business_email: data.email,
        password: data.password,
        firstname: data.firstname,
        lastname: data.lastname,
        mobile_no: data.mobile_no,
        business_phone: data.business_phone,
        business_name: data.businessName,
        business_address: data.businessAddress
      };
      dispatch(userSignup(transformedData));
    } else {
      toast.error('Invalid data!');
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateContactNumber = (contactNumber) => {
    // Allow a '+' sign followed by one or more digits
    var re = /^\+\d+$/;
    return re.test(contactNumber);
  };

  const formatPhoneNumber = (value) => {
    if (!value) return '';
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length > 0) {
      const formattedValue = cleaned
        .match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)
        ?.slice(1)
        ?.filter(Boolean)
        ?.join('-');
      return formattedValue;
    } else {
      return '';
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          mobile_no: '',
          business_phone: '',
          businessName: '',
          businessAddress: '',
          password: '',
          confirmPassword: ''
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstname) {
            errors.firstname = 'First name is required!';
          }
          if (!values.lastname) {
            errors.lastname = 'Last name is required!';
          }
          if (!values.email) {
            errors.email = 'Business Email is required!';
          }
          if (!values.mobile_no) {
            errors.mobile_no = 'Contact is required!';
          }
          if (!values.business_phone) {
            errors.business_phone = 'Contact is required!';
          }
          if (!values.businessName) {
            errors.businessName = 'Business Name is required!';
          }
          if (!values.businessAddress) {
            errors.businessAddress = 'Business Address is required!';
          }
          if (!values.password) {
            errors.password = 'Password is required!';
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = 'Confirm Password!';
          }
          if (values.confirmPassword !== values.password) {
            errors.confirmPassword = 'Passwords do not match!';
          }
          if (!validateEmail(values.email)) {
            errors.email = 'Invalid Business Email';
          }
          if (!values.mobile_no || values?.mobile_no?.length !== 12) {
            errors.mobile_no = 'Invalid Mobile No number';
          }
          if (!values.business_phone || values?.business_phone?.length !== 12) {
            errors.business_phone = 'Invalid Business Phone number';
          }
          return errors;
        }}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field fullWidth name="firstname">
                  {(props) => <TextInput label={'First Name'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="lastname">
                  {(props) => <TextInput label={'Last Name'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="businessName">
                  {(props) => <TextInput label={'Business Name'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="email">
                  {(props) => <TextInput label={'Business Email'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="mobile_no" format={(value) => formatPhoneNumber(value)}>
                  {(props) => <TextInput label={'Mobile Number'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="business_phone" format={(value) => formatPhoneNumber(value)}>
                  {(props) => <TextInput label={'Business Phone'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="businessAddress">
                  {(props) => <TextInput label={'Business Address'} {...props} />}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="password">
                  {(props) => (
                    <TextInput
                      label={'Password'}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...props}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field fullWidth name="confirmPassword">
                  {(props) => (
                    <TextInput
                      label={'Confirm password'}
                      type={showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...props}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 3 }}>
              Already have an account?
              <Link
                component={RouterLink}
                to="/auth/login"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  ml: 1,
                  color: '#03396c',
                  textDecoration: 'none', // Ensure that textDecoration is set to 'none'
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Login
              </Link>
            </Stack>
            <div className="buttons">
              <LoadingButton
                fullWidth
                loading={loading}
                size="large"
                type="submit"
                variant="contained"
              >
                SIGN UP
              </LoadingButton>
            </div>
          </form>
        )}
      />
    </>
  );
}
