import React, { useEffect, useState } from 'react';
import { Card, Stack, Container, Typography, Skeleton, Button } from '@mui/material';
import { baseURL } from 'src/constants/baseURL';
import { toast } from 'react-toastify';
import Iconify from 'src/components/iconify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from 'src/features/auth/Api';

export default function SubscriptionSucess() {
  const [loading, setLoading] = useState(true);
  const [params] = useSearchParams();

  const auctioneer_id = params.get('auctioneer_id');
  const isSuccess = params.get('redirect_status') === 'succeeded';
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    subscriptionCallBack();
    // eslint-disable-next-line
  }, []);

  const subscriptionCallBack = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      await api.post(
        `${baseURL}/subscribe/subscribe-auctioneer`,
        {
          user_id: userInfo?.user_id,
          auctioneer_id: auctioneer_id
        },
        config
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message || 'Something went wrong');
      console.log('[Error]', error);
    }
  };

  if (loading) {
    return <Skeleton variant="rectangular" width={'100%'} height={300} />;
  }

  return (
    <Container>
      <Card>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          margin={'40px'}
          gap={5}
        >
          {isSuccess ? (
            <Iconify icon="mdi:shop-complete" width="100px" />
          ) : (
            <Iconify icon="mdi:alert-circle-outline" width="100px" />
          )}
          <Typography variant="h4" sx={{ mb: 1 }}>
            {isSuccess ? 'Subscribed Successfully' : 'Something went wrong. Please try again.'}
          </Typography>
          <Button variant="contained" onClick={() => navigate('/dashboard/app')}>
            Go to Opportunity
          </Button>
        </Stack>
      </Card>
    </Container>
  );
}
