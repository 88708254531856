import React from 'react';
import { Grid, Typography, Button, Box, Link } from '@mui/material';

const footerContainer = {
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  justifyContent: 'center',
  backgroundColor: '#f2f0f1',
  flexDirection: 'column',
  marginTop: 'auto',
  marginBottom: '0px !important',
  paddingBottom: '0px !important'
};

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <>
      <Box style={{ ...footerContainer }}>
        <Typography style={{ paddingBottom: '0px' }}>
          Provided by{' '}
          <Link href="https://infinitibizsol.com" target="_blank" underline="none">
            Infiniti Business Solutions
          </Link>
        </Typography>
        <Typography style={{ opacity: '0.4' }}>
          @copyright {date} - Built by Infiniti Business Solutions
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
