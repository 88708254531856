import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL } from 'src/constants/baseURL';
import api from '../auth/Api';

export const getInventoryVehicles = createAsyncThunk(
  'user/get',
  async ({ timeRange, sortBy, orderDirection, userId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/vehicle/get-inventory-vehicles/${userId}`,
        { timeRange, sortBy, orderDirection },
        config
      );
      if (data) {
        console.log('Inventory Vehicles', data);
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
