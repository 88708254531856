import { Form, Field } from 'react-final-form';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import TextInput from 'src/components/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from 'src/constants/baseURL';

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ business_email }) => {
    setLoading(true);
    if (business_email) {
      try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const { data } = await axios.post(
          `${baseURL}/auth/forgot-password`,
          { business_email },
          config
        );
        if (data) {
          toast.success(data?.data?.notification || 'Email sent!');
          setLoading(false);
        }
      } catch (error) {
        console.log('🚀 ~ onSubmit ~ error:', error);
        toast.error(error?.response?.data?.message || 'Error while forgetting password!');
        setLoading(false);
      }
    }
  };

  const validateEmail = (business_email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(business_email);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ business_email: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.business_email) {
            errors.business_email = 'Business Email is required!';
          }
          if (!validateEmail(values.business_email)) {
            errors.business_email = 'Invalid Business Email';
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} mb={4}>
              <Field fullWidth name="business_email">
                {(props) => <TextInput label={'Enter Business Email'} {...props} />}
              </Field>
            </Stack>
            <div className="buttons">
              <LoadingButton
                fullWidth
                loading={loading}
                size="large"
                type="submit"
                variant="contained"
              >
                SEND EMAIL
              </LoadingButton>
            </div>
          </form>
        )}
      />
    </>
  );
};

export default ForgotPasswordForm;
