import SvgColor from '../../../components/svg-color';
import { useSelector } from 'react-redux';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const pngIcon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const adminSidebar = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('home')
  },
  {
    title: 'inventory',
    path: '/dashboard/inventory',
    icon: pngIcon('icon_cars')
  },
  {
    title: 'opportunities',
    path: '/dashboard/opportunities',
    icon: icon('ic_calendar')
  },
  // {
  //   title: "payment Methods",
  //   path: "/dashboard/settings/paymentMehods",
  //   icon: pngIcon("portfolio"),
  // },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: icon('settings')
  }
];

export const NavConfig = () => {
  return adminSidebar;
};

export default NavConfig;
