import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../assets/logo.png';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import SignupForm from 'src/sections/auth/login/SignupForm';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 576,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function SignupPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <StyledRoot>
      <Container maxWidth="md">
        <StyledContent>
          <Stack direction="row" alignItems="center" justifyContent="center" marginBottom={8}>
            <img
              src={Logo}
              width={250}
              height={150}
              style={{
                resize: 'contain',
                background: '#f9fafb'
              }}
            />
          </Stack>
          <SignupForm />
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
