import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/userSlice';
import inventoryReducer from '../features/inventory/inventorySlice';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  inventory: inventoryReducer
});

export default rootReducer;
