import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseURL } from 'src/constants/baseURL';
import { styled } from '@mui/material/styles';
import { Container, Stack } from '@mui/material';
import { Form, Field } from 'react-final-form';
import { LoadingButton } from '@mui/lab';
import TextInput from 'src/components/TextInput/TextInput';
import Logo from '../assets/logo.png';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validateToken = async () => {
    try {
      const response = await axios.post(`${baseURL}/auth/validate-token`, { token });
      if (response.status === 200) {
        toast.info('You can update your password');
      }
    } catch (error) {
      toast.error('Invalid or Expired Token');
      setTimeout(() => {
        navigate('/auth/forgot-password');
      }, 2000);
    }
  };

  const onSubmit = async ({ password }) => {
    setLoading(true);
    if (password) {
      try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const { data } = await axios.post(
          `${baseURL}/auth/reset-password`,
          { newPassword: password, token },
          config
        );
        if (data) {
          toast.success(data?.data?.message || 'Password Updated!');
          setLoading(false);
          setTimeout(() => {
            navigate('/auth/login');
          }, 2000);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'Error while resetting password! Try again.');
        setLoading(false);
        setTimeout(() => {
          navigate('/auth/forgot-password');
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      return setTimeout(() => {
        toast.error('Token not found!');
        return navigate('/auth/forgot-password');
      }, 1500);
    }
    validateToken();
  }, [token]);

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Stack direction="row" alignItems="center" justifyContent="center" marginBottom={8}>
              <img
                src={Logo}
                width={250}
                height={150}
                style={{
                  resize: 'contain',
                  background: '#f9fafb'
                }}
              />
            </Stack>

            <Form
              onSubmit={onSubmit}
              initialValues={{ password: '', confirmPassword: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = 'Password is required!';
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = 'Confirm Password!';
                }
                if (values.confirmPassword !== values.password) {
                  errors.confirmPassword = 'Passwords do not match!';
                }
                return errors;
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={3} mb={4}>
                    <Field fullWidth name="password">
                      {(props) => <TextInput label={'Password'} type="text" {...props} />}
                    </Field>
                    <Field fullWidth name="confirmPassword">
                      {(props) => <TextInput label={'Confirm password'} type="text" {...props} />}
                    </Field>
                  </Stack>
                  <div className="buttons">
                    <LoadingButton
                      fullWidth
                      loading={loading}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      UPDATE PASSWORD
                    </LoadingButton>
                  </div>
                </form>
              )}
            />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default ResetPassword;
