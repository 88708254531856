import { createSlice } from '@reduxjs/toolkit';
import { userLogin, userSignup } from './authActions';
import secureLocalStorage from 'react-secure-storage';

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
  isLoggedIn: secureLocalStorage.getItem('userToken') !== null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = null;
    },
    updateUserInfo(state, action) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload
      };
    }
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.userInfo = payload;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userSignup.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userSignup.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isLoggedIn = true;
      state.userInfo = payload;
    },
    [userSignup.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default authSlice.reducer;
export const { logout, updateUserInfo } = authSlice.actions;
