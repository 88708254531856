import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

const heroBox = {
  width: '100%',
  display: 'flex',
  // minHeight: '600px',
  alignItems: 'center',
  justifyContent: 'center'
};

const gridContainer = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1400px',
  padding: '50px'
};

const Download = () => {
  return (
    <Box style={heroBox}>
      <Grid container spacing={6} style={gridContainer}>
        <Grid item xs={12} md={7} style={{ paddingLeft: '0 !important' }}>
          <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
            Download Our App
          </Typography>
          <Typography variant="subtitile2">
            The definitive digital solution designed specifically for Wholesalers in the auto
            auction industry. With our robust web and mobile applications, you're empowered to
            streamline your operations, enhance communication with Auctioneers, and boost your sales
            performance like never before.
          </Typography>

          <Grid container spacing={2} alignItems="center" marginTop="15px">
            <Grid item>
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  width: '100%',
                  fontSize: '16px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  color: '#ffffff',
                  backgroundColor: 'black',
                  gap: '10px'
                }}
                href="https://play.google.com/store/apps/details?id=com.auction.flow&hl=en&gl=US"
                target="_blank"
              >
                <AndroidIcon fontSize="large" />
                <div style={{ textAlign: 'left', lineHeight: '1.3' }}>
                  Download from
                  <br />
                  Google Play
                </div>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  width: '100%',
                  fontSize: '16px',
                  display: 'flex',
                  justifyContent: 'space-around',
                  color: '#ffffff',
                  backgroundColor: 'black',
                  gap: '10px'
                }}
                href="https://apps.apple.com/th/app/auction-flow/id6478970886"
                target="_blank"
              >
                <AppleIcon fontSize="large" />
                <div style={{ textAlign: 'left', lineHeight: '1.3' }}>
                  Download from
                  <br />
                  App Store
                </div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={'/assets/images/mobile-app.png'} alt="App Preview" width={'100%'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Download;
