import React from 'react';
import { Grid, Typography, Button, Box, Link } from '@mui/material';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { NavLink as RouterLink } from 'react-router-dom';

const backgroundImageBox = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
  backgroundImage: "url('/assets/images/cars.jpg')",
  animation: 'zoomIn 4s ease-in',
  transform: 'scale(1.1)',
  zIndex: '-1'
};

const heroBox = {
  width: '100%',
  display: 'flex',
  minHeight: '600px',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden'
};

const heroOverlay = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)' // Adjust opacity as needed
};

const gridContainer = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1400px',
  width: '100%',
  padding: '50px',
  zIndex: '100'
};

const aboutUsContainer = {
  width: '100%',
  display: 'flex',
  minHeight: '400px',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '30px 0px 50px 0px'
};

const aboutUsSubtitle = {
  opacity: '0.7',
  paddingBottom: '30px',
  fontSize: '18px'
};

const styles = `
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
`;

const sectionItems = [
  {
    id: 1,
    icon: <SpaceDashboardIcon sx={{ fontSize: 100 }} color="primary" />,
    title: 'Dashboard',
    sentence:
      'Get a comprehensive overview of your sales, revenue, and profits with our intuitive dashboard. Monitoring your business performance has never been easier.'
  },
  {
    id: 2,
    title: 'Inventory Management',
    icon: <DirectionsCarFilledOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
    sentence:
      'Effortlessly build and manage your vehicle inventory. Our platform simplifies the process, making it easy for you to organize and track your vehicles.'
  },
  {
    id: 3,
    title: 'Opportunity Section',
    icon: <PaidOutlinedIcon sx={{ fontSize: 100 }} color="primary" />,
    sentence:
      'Revolutionize the way you assign vehicles to Auctioneers. Select any week of the year and assign vehicles from your inventory to your preferred Auctioneers. Pass critical vehicle information seamlessly and receive real-time sales data as your vehicles make their mark at auctions.'
  },
  {
    id: 4,
    title: 'Portfolio Management',
    icon: <BusinessCenterIcon sx={{ fontSize: 100 }} color="primary" />,
    sentence:
      'Keep track of your subscriptions and Auctioneer partnerships with ease. Our platform allows you to manage your connections and preferences, ensuring you are always aligned with the best.'
  }
];

const sectionGridContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  minHeight: '500px',
  maxWidth: '1400px',
  width: '100%'
};

const sectionGridItem = {
  backgroundColor: '#f2f0f1',
  textAlign: 'center',
  padding: '30px',
  width: '200px',
  borderRadius: '10px',
  margin: '10px'
};

const LandingPage = () => {
  return (
    <>
      <style>{styles}</style>
      <Box style={{ ...heroBox }}>
        <Box sx={{ ...heroOverlay }}></Box>
        <div style={{ ...backgroundImageBox }}></div>
        <Grid container spacing={6} style={{ ...gridContainer, color: '#ffffff' }}>
          <Grid item xs={12} md={7} style={{ paddingLeft: '0 !important' }}>
            <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
              Welcome to Auction-Flow
            </Typography>
            <Typography variant="subtitile2">
              The definitive digital solution designed specifically for Wholesalers in the auto
              auction industry. With our robust web and mobile applications, you're empowered to
              streamline your operations, enhance communication with Auctioneers, and boost your
              sales performance like never before.
            </Typography>
            <Button
              component={RouterLink}
              to={'/download'}
              variant="contained"
              color="primary"
              sx={{
                width: '200px',
                fontSize: '16px',
                marginTop: '15px',
                display: 'block',
                textAlign: 'center'
              }}
            >
              Download Now
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* // Section */}

      <Box sx={{ flexGrow: 1, minHeight: '420px', textAlign: 'center' }}>
        <Grid container style={{ ...sectionGridContainer }}>
          {sectionItems.map((item) => (
            <Grid
              item
              xs={12}
              md={2.7}
              minHeight={400}
              key={item.id}
              style={{ ...sectionGridItem }}
            >
              {item.icon}
              <Typography variant="h4">{item?.title}</Typography>
              <Typography>{item.sentence}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* // About US */}

      <Box style={{ ...aboutUsContainer }}>
        <Grid container spacing={6} style={{ ...gridContainer }}>
          <Grid item xs={12} md={5}>
            <img src="/assets/images/graph.png" alt="My Team" width={'100%'} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" fontWeight={700} style={{ paddingBottom: '15px' }}>
              Embrace Efficiency with Auction-Flow
            </Typography>
            <Typography style={{ ...aboutUsSubtitle }}>
              Say goodbye to outdated communication methods. Our platform is designed to foster
              seamless, efficient interactions between you and your Auctioneers, propelling your
              wholesaling business to new heights.
            </Typography>
            <Button variant="contained" color="primary" sx={{ width: '200px', fontSize: '16px' }}>
              CONTACT US
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LandingPage;
