import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL } from 'src/constants/baseURL';
import api from '../auth/Api';

export const getUserAuctioneerList = createAsyncThunk(
  'user/get',
  async ({ userId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/user/user-added-auctioneers`,
        { userId, weekId },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const filterByAuction = createAsyncThunk(
  'cars/filter-by-auctioneer-auctions',
  async ({ user_id, week_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auctions-lanes/auctioneer-auctions`,
        { user_id, week_id },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const filterConditionLight = createAsyncThunk(
  'cars/sort-by-condition-light',
  async ({ user_id, week_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/vehicle/filter-by-condition-light`,
        { user_id, week_id },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const filterSaleStatus = createAsyncThunk(
  'cars/sale-status',
  async ({ user_id, week_id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/vehicle/filter-by-salestatus`,
        { user_id, week_id },
        config
      );
      if (data) {
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAssignedVehiclestoAuctioneer = createAsyncThunk(
  'cars/get',
  async ({ opportunity_id, user_id, weekId, sortBy, orderDirection }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/user/get-assigned-vehicles`,
        { opportunity_id, user_id, weekId, sortBy, orderDirection },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getSaleStatusVehicles = createAsyncThunk(
  'cars/get',
  async ({ week_id, user_id, sale_status, sortBy, orderDirection }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/vehicle/get-sale-status-vehicles`,
        { week_id, user_id, sale_status, sortBy, orderDirection },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getConditionLightVehicles = createAsyncThunk(
  'cars/get',
  async ({ week_id, user_id, condition_light, sortBy, orderDirection }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/vehicle/get-condition-light-vehicles`,
        { week_id, user_id, condition_light, sortBy, orderDirection },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getVehiclesByAuction = createAsyncThunk(
  'cars/get',
  async (
    { auction_id, user_id, week_id, sortBy, orderDirection, filter_type },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/auction/vehicles-by-auctionId`,
        { auction_id, user_id, week_id, sortBy, orderDirection, filter_type },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAuctioneerToSubscribeList = createAsyncThunk(
  'auctioneer/get',
  async ({ userId, weekId }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/subscribe/subscribed-auctioneer-list`,
        { userId, weekId },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getPaymentMethodsList = createAsyncThunk(
  'payment-methods/get',
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(`${baseURL}/payment/payment-methods`, { email }, config);
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getSubscriptionList = createAsyncThunk(
  'subscription/get',
  async ({ email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await api.post(
        `${baseURL}/payment/get-subscription-details`,
        { email },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const newAssignVehicleWithStatus = createAsyncThunk(
  'assign-vehicle-with-Status',
  async ({ user_id, sortBy, orderDirection }, { rejectWithValue }) => {
    try {
      const config = {
        headers: { 'Content-Type': 'application/json' }
      };
      const { data } = await api.post(
        `${baseURL}/user/get-all-vehicles-with-status`,
        { user_id, sortBy, orderDirection },
        config
      );
      if (data) {
        return data?.data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
