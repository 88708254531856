import { createSlice } from '@reduxjs/toolkit';
import { getInventoryVehicles } from './inventoryActions';
const initialState = {
  loading: false,
  InventoryData: [],
  error: null,
  success: false
};

const usersSlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: {
    [getInventoryVehicles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getInventoryVehicles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.InventoryData = payload?.data;
    },
    [getInventoryVehicles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default usersSlice.reducer;
