import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseURL } from 'src/constants/baseURL';
import secureLocalStorage from 'react-secure-storage';

export const userSignup = createAsyncThunk(
  'auth/signup',
  async (transformedData, { rejectWithValue }) => {
    console.log({ transformedData });
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(`${baseURL}/auth/signup`, transformedData, config);

      if (data) {
        console.log('Signup payload', data);
        secureLocalStorage.setItem('userToken', data?.data[0]?.token);
        return data?.data[0];
      }
    } catch (error) {
      if (error.response) {
        console.log('error', error?.response?.data?.message);
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(
        `${baseURL}/auth/login`,
        {
          business_email: email,
          password
        },
        config
      );

      if (data) {
        console.log('user payload', data?.data[0]);
        secureLocalStorage.setItem('userToken', data?.data[0]?.token);
        secureLocalStorage.setItem("expirationTime", data?.data[0]?.expirationTime);
        return data?.data[0];
      }
    } catch (error) {
      if (error.response) {
        console.log('error', error?.response?.data?.message);
        return rejectWithValue(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ business_email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const { data } = await axios.post(
        `${baseURL}/auth/forgot-password`,
        {
          business_email
        },
        config
      );

      if (data) {
        // You can perform any necessary actions upon successful response here
        console.log('Forgot password email sent:', data);
        return data;
      }
    } catch (error) {
      if (error.response) {
        throw new Error(error?.response?.data?.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
