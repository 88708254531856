import { createSlice } from '@reduxjs/toolkit';
import {
  getAuctioneerToSubscribeList,
  getUserAuctioneerList,
  filterConditionLight,
  filterSaleStatus,
  filterByAuction,
  getAssignedVehiclestoAuctioneer,
  getSaleStatusVehicles,
  getConditionLightVehicles,
  getVehiclesByAuction,
  getPaymentMethodsList,
  newAssignVehicleWithStatus
} from './userActions';
const initialState = {
  loading: false,
  userData: null,
  filterType: null,
  auctioneerData: null,
  paymentData: null,
  vehiclesData: null,
  conditionLight: false,
  saleStatus: false,
  notSoldVehicles: null,
  redVehicles: null,
  yellowVehicles: null,
  greenVehicles: null,
  error: null,
  success: false,
  assignVehicleWithStatus: null
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setFilterType(state, action) {
      state.filterType = action.payload;
    }
  },
  extraReducers: {
    [getUserAuctioneerList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserAuctioneerList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload?.data;
      state.filterType = payload?.filter_type;
    },
    [getUserAuctioneerList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [filterByAuction.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [filterByAuction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload?.data;
      state.filterType = payload?.filter_type;
    },
    [filterByAuction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [filterConditionLight.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [filterConditionLight.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload?.data;
      state.filterType = payload?.filter_type;
    },
    [filterConditionLight.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [filterSaleStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [filterSaleStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload?.data;
      state.filterType = payload?.filter_type;
    },
    [filterSaleStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getAssignedVehiclestoAuctioneer.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAssignedVehiclestoAuctioneer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vehiclesData = payload;
    },
    [getAssignedVehiclestoAuctioneer.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getVehiclesByAuction.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getVehiclesByAuction.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vehiclesData = payload;
    },
    [getVehiclesByAuction.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getSaleStatusVehicles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSaleStatusVehicles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vehiclesData = payload;
    },
    [getSaleStatusVehicles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getConditionLightVehicles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getConditionLightVehicles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.vehiclesData = payload;
    },
    [getConditionLightVehicles.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getAuctioneerToSubscribeList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAuctioneerToSubscribeList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auctioneerData = payload;
    },
    [getAuctioneerToSubscribeList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getPaymentMethodsList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPaymentMethodsList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.paymentData = payload;
    },
    [getPaymentMethodsList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [newAssignVehicleWithStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [newAssignVehicleWithStatus.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.assignVehicleWithStatus = payload;
    },
    [newAssignVehicleWithStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

export default usersSlice.reducer;

export const { setFilterType } = usersSlice.actions;
