import { useEffect } from 'react';
import Router from './routes';
import ThemeProvider from './theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './features/auth/authSlice';
import secureLocalStorage from 'react-secure-storage';

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
      const handleLogout = () => {
          dispatch(logout());
          secureLocalStorage.clear();
          toast.info('Session Timed out.');
          navigate('/auth/login');
      };

      if (!isLoggedIn && window.location.pathname.includes('dashboard')) {
          navigate('/auth/login');
      }
      else if(isLoggedIn){
          const expirationTime = Number(secureLocalStorage.getItem("expirationTime"));
          const timeLeft = expirationTime - Date.now();

          if (timeLeft <= 0) {
              handleLogout();
          } else {
              const timeoutId = setTimeout(handleLogout, timeLeft);
              return () => clearTimeout(timeoutId);
          }
      }
  }, [isLoggedIn]);

  return (
    <ThemeProvider>
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </ThemeProvider>
  );
}
